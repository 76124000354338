import React from 'react';
import styled from 'styled-components';

import { static_images } from '../initialData';

const CustomImg = styled.img`
  height: 100%;
  width: 100%;
`;
const Rocket = () => {
  return <CustomImg src={static_images.rocket} alt='rocket' />;
};

export default Rocket;
