import * as types from "../actionTypes";
import { beginApiCall } from "./apiStatusActions";
import { static_images } from "../../initialData";

export function loadStaticImagesSuccess(static_images) {
    return { type: types.LOAD_STATIC_IMAGE_SUCCESS, static_images };
}

export function loadStaticImages() {
    return function(dispatch) {
        dispatch(beginApiCall());
        return dispatch(loadStaticImagesSuccess(static_images));
    };
}