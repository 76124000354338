import { handleResponse, handleError } from './utils';
import axios from 'axios';

const api = axios.create({
  baseURL: '/',
});
const headers = {
  headers: {
    'X-CSRFToken': document.getElementsByName('csrfmiddlewaretoken')[0]
      .defaultValue,
  },
};

export async function login(data) {
  try {
    const response = await api.post('login/', data, headers);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function resetPassword(data) {
  try {
    const response = await api.post('forgot-password/', data, headers);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function forceChangePassword(data) {
  try {
    const response = await api.post('force_change_password/', data, headers);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function sendEmailConfirmation(data) {
    try {
        const response = await api.post("send_email_confirmation/", data, headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function getPlans() {
    try {
        const response = await api.get("api/load-plans/", headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function getPayments() {
    try {
        const response = await api.get("api/load-payments/", headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function loadAccount(reference_id) {
    try {
        const response = await api.get(`api/order/${reference_id}/`, headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function loadTransactionReceipt(reference_id) {
    try {
        const response = await api.get(`api/order/${reference_id}/receipt/`, headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function cancelTransaction(reference_id) {
    try {
        const response = await api.patch(`api/cancel-transaction/${reference_id}/`, {}, headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function availDiscount(data) {
    try {
        const response = await api.post("api/avail-discount/", data, headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function dragonpayAcceptPayment(data) {
    try {
        const response = await api.post("api/dragonpay-accept-payment/", data, headers);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
}

export async function sso_authentication(data) {
  try {
    const response = await api.post(
      'sso/authentication/google-oauth2/',
      data,
      headers
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function sso_complete_callback(data) {
  try {
    const response = await api.post(
      'sso/complete/google-oauth2/',
      data,
      headers
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
