import React from "react";

import DivInput from "./DivInput";
import Button from "./common/Button";

function ForceChangePasswordForm({
    history,
    users,
    errors,
    onChange,
    handleForceChangePassword,
    process,
    handleLogout
}) {

    return (
        <div className="bg-loginscreen">
            <div className="middle-box text-center loginscreen wow fadeIn">
                <div className="loginscreen-border remove-border">
                    <div className="login-form">
                        <h3>Congratulations on your first login</h3>
                        <p>We suggested to change your generated password for your own security.</p>
                        <form onSubmit={handleForceChangePassword}>
                            <DivInput 
                                divClassName="form-group custom-input" 
                                inputType="password" 
                                inputName="password"
                                inputClassname="form-control"
                                inputPlaceHolder="New Password"
                                inputOnChange={onChange}
                                inputValue={users.password} 
                                inputError={errors.password}
                            />
                            <DivInput 
                                divClassName="form-group custom-input" 
                                inputType="password" 
                                inputName="repassword"
                                inputClassname="form-control"
                                inputPlaceHolder="Confirm Password"
                                inputOnChange={onChange}
                                inputValue={users.repassword} 
                                inputError={errors.repassword}
                            />
                            <Button 
                                type="submit" 
                                className="btn btn-primary block full-width m-b btn-sigin" 
                                text="Save" 
                                process={process.change_password} 
                                processMsg="Saving..." 
                            />
                            <Button 
                                type="button" 
                                className="btn btn-primary block full-width m-b btn-sigin" 
                                text="Logout" 
                                onClick={handleLogout}
                                process={process.logout} 
                                processMsg="Logging out..." 
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className="navy-line"></div>
        </div>
    )
}

export default ForceChangePasswordForm;