import React from "react";
import { Link } from "react-router-dom";

import DivInput from "./DivInput";
import ButtonWithImage from "./common/ButtonWithImage";
import Button from "./common/Button";

import ForceChangePasswordPage from "./ForceChangePasswordPage";

function LoginForm({
    openNewTabPrivacy,
    openNewTabTermsService,
    static_images,
    is_otp,
    user,
    users,
    errors,
    process,
    privacy_url,
    usls_privacy_policy,
    onChange,
    onLogin,
    sendEmailConfirmation,
    renderAccountsList,
    userAccountName,
    userAccountEmail,
    signInWithGoogle,
}) {

    return (
        <div className="bg-loginscreen">
            <div className="middle-box text-center loginscreen wow fadeIn">
                {
                    !is_otp
                    ? <div className="loginscreen-border">
                    <div className="logo mb-3">
                        <img src={static_images.yp_login_logo} alt="login-logo" />
                    </div>
                    <div className="login-form">
                        <h3>Sign In</h3>
                        <p>One account. Multiple Device.</p>
                        <form onSubmit={onLogin}>
                            <DivInput 
                                divClassName="form-group custom-input" 
                                inputType="text" 
                                inputName="email"
                                inputClassname="form-control"
                                inputPlaceHolder="Email"
                                inputOnChange={onChange}
                                inputValue={users.email} 
                                inputError={errors.email}
                            />
                            <DivInput 
                                divClassName="form-group custom-input" 
                                inputType="password" 
                                inputName="password"
                                inputClassname="form-control"
                                inputPlaceHolder="Password"
                                inputOnChange={onChange}
                                inputValue={users.password} 
                                inputError={errors.password}
                            />
                            <ButtonWithImage 
                                type="submit" 
                                className="btn btn-primary block full-width m-b btn-sigin custom-mb" 
                                text="Sign In" 
                                img={static_images.lock_logo} 
                                process={process} 
                                processMsg="Signing in..." 
                            />
                            <div className="py-4" style={{position: "relative"}}>
                                <span style={{
                                    padding: "7px",
                                    backgroundColor: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}>OR</span>
                                <hr style={{margin: "unset"}} />
                            </div>
                            <ButtonWithImage
                                type="button" 
                                className="btn btn-primary block full-width m-b btn-sigin btn-google" 
                                text="Continue with Google"
                                img={static_images.google_logo} 
                                onClick={() => signInWithGoogle()}
                            />
                            <div className="text-center forgot-password mb-3">
                                <Link to="/reset-password">Forgot Password?</Link>
                            </div>
                        </form>
                    </div>
                    <p className="text-center aggrement">By selecting Sign In, you agree to our Terms and have read and acknowledge our Global Privacy Statement.</p>
                    <div className="text-center privacy-btn">
                        <a id="privacy" href="#" onClick={(event) => openNewTabPrivacy(event)}>
                            Privacy Notice
                        </a>
                    </div>
                    <div className="text-center privacy-btn">
                        <a id="terms" href="#" onClick={(event) => openNewTabTermsService(event)}>
                            Terms of Service
                        </a>
                    </div>
                    <div style={{marginTop: '5%', marginBottom: '5%'}}>
                        <table>
                            <tr>
                                <td>
                                    <img style={{width: '53%', marginRight: '-40%'}} src={static_images.iso} alt="iso-logo" />
                                </td>
                                <td style={{width: '50%'}}>
                                    <img style={{width: '70%', marginLeft: '-35%'}} src={static_images.gdpr1} alt="gdpr1-logo" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                : <div className="loginscreen-border">
                    <div className="logo mb-3">
                        <img src={static_images.yp_login_logo} alt="login-logo" />
                    </div>
                    <div className="login-form">
                        <h3>One Time Password</h3>
                        <p>We have sent a code to your email. Please don't share your code to anyone!</p>
                        <form onSubmit={(event) => onLogin(event, false)}>
                            <DivInput 
                                divClassName="form-group custom-input" 
                                inputType="text" 
                                inputName="code"
                                inputClassname="form-control"
                                inputPlaceHolder="Code"
                                inputOnChange={onChange}
                                inputValue={users.code} 
                                inputError={errors.code}
                            />
                            <Button 
                                type="submit" 
                                className="btn btn-primary block full-width m-b btn-sigin" 
                                text="Submit"
                                process={process} 
                                processMsg="Verifying..." 
                            />
                        </form>
                        <div className="text-center forgot-password mb-2">
                            <a href="#" onClick={(event) => onLogin(event, true)}>Didn't receive an email for the code? Click here to resend.</a>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="modal inmodal" id="userAccountsSelection" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content animated bounceInUp top_bottom_radius">
                        <div className="model-header" id="selectUserHeader">
                            <div className="pull-right"><button className="btn btn-sm btn-link" data-dismiss="modal"><i className="fa fa-times-circle fa-2x"></i></button>       </div>
                            <h3 className="panel-title" style={{fontSize:'large'}}>Select User</h3>
                        </div>
                        <div className="modal-body">
                            <br></br>
                            <center><h2 style={{fontWeight:'bold'}}><strong>Welcome back, {userAccountName()} <img style={{height:'25px',marginBottom:'10px'}} src={static_images.select_user_wave} alt="select_user_wave" /></strong></h2></center>
                            <br></br>
                            <center><span style={{color:'grey', fontSize:'medium'}}>We have noticed that you have two (2) user access.<br></br>
                                Please select user you want to sign in.
                            </span></center>
                            <br></br>
                            <div className="table-responsive">
                                <table className="table sticky-table-header table-hover">
                                    <thead>
                                        <tr className="hdr_border_r table-center">
                                            <th style={{width:'50%'}}>Name</th>
                                            <th style={{width:'50%'}}>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-center">
                                        {renderAccountsList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal inmodal" id="userPrivacyPolicy" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content animated bounceInUp">
                        <div className="modal-body">
                            <iframe id="myFrame" src={usls_privacy_policy}></iframe>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                            <button 
                                type="button" 
                                id="btnUserPrivacyPolicy" 
                                className="btn" 
                                style={{ backgroundColor: '#4350AF', color: 'white'}}
                                onClick={(event) => onLogin(event, false, false, true)}>Agree and Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal inmodal" id="unConfirmedAccountModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content animated bounceInUp top_bottom_radius">
                        <div className="model-header" id="accountVerification">
                            <div className="pull-right"><button style={{color:'#C7C7C7'}} className="btn btn-sm btn-link" data-dismiss="modal"><i className="fa fa-times-circle fa-2x"></i></button></div>
                        </div>
                        <div className="modal-body" style={{background:'white', borderBottomLeftRadius:'15px', borderBottomRightRadius:'15px'}}>
                            <center style={{marginTop:'-30px'}}><h2 style={{fontWeight:'bold'}}><strong><img style={{height:'100px',marginBottom:'10px'}} src={static_images.error_logo} alt="error" /></strong></h2>
                            <h1 style={{fontWeight:'bold',color:'#FF3D00'}}><strong>Account Verification!</strong></h1>
                            </center>
                            <br></br>
                            <center><span style={{color:'#373530', fontSize:'medium'}}>Hey there! <img style={{height:'20px'}} src={static_images.select_user_wave} alt="select_user_wave" /> We have found that your account is not <br></br>
                                yet verified. An email confirmation was sent to <br></br>
                                <span style={{color:'#4150B7'}}><b>{userAccountEmail()}</b></span> for verification.
                            </span></center>
                            <br></br>
                            <center>
                                <span style={{color:'#878787', fontSize:'medium'}}>
                                    Didn't receive an email? Click resend.
                                </span><br></br><br></br>
                                <button style={{marginTop:'5px'}} className="btn btn-lg btn-success resend_button" onClick={(event) => sendEmailConfirmation(event)}>RESEND</button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal inmodal" id="noEmployeeEmailAddressModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content animated bounceInUp top_bottom_radius">
                        <div className="model-header" id="accountVerification">
                            <div className="pull-right"><button style={{color:'#C7C7C7'}} className="btn btn-sm btn-link" data-dismiss="modal"><i className="fa fa-times-circle fa-2x"></i></button></div>
                        </div>
                        <div className="modal-body" style={{background:'white', borderBottomLeftRadius:'15px', borderBottomRightRadius:'15px'}}>
                            <center style={{marginTop:'-30px'}}><h2 style={{fontWeight:'bold'}}><strong><img style={{height:'100px',marginBottom:'10px'}} src={static_images.error_logo} alt="error" /></strong></h2>
                            <h1 style={{fontWeight:'bold',color:'#FF3D00'}}><strong>Sorry, we found an error! <img style={{height:'30px',marginBottom:'10px'}} src={static_images.sad_face} alt="sad_face" /></strong></h1>
                            </center>
                            <br></br>
                            <center>
                                <span style={{color:'#373530', fontSize:'medium'}}>
                                    No email address was found in your<br></br>
                                    employee information. Please <span style={{color:'#4150B7'}}><b>contact your ADMIN/<br>
                                    </br>HR Personnel</b></span> to update your email address.
                                    <br></br>
                                    <br></br>
                                    Thank you and GOD bless!
                                </span
                            ></center>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navy-line"></div>
        </div>
    )
}

export default LoginForm;