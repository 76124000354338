import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import axios from 'axios';

import * as api from '../api/api';
import classNames from '../helpers/classNames';

import InformationForm from './InformationForm';
import AddressForm from './AddressForm';
import PaymentOption from './PaymentOption';
import OrderedSection from './OrderedSection';

import { static_images } from "../initialData";

const static_url = document.getElementsByName('static_url')[0].defaultValue;
const privacyUrl = `${static_url}landing_page/pdf/Privacy Notice.pdf`;

const BackButton = styled.button`
  position: absolute;
  border: 0;
  background: transparent;
  font-size: 1.4rem;
  color: #2757ed;
  top: 2.5rem;
  left: 1.7rem;

  &:hover {
    color: #4f80ff !important;
    background: transparent;
  }
`;
const CheckoutFormDiv = styled.div`
  padding: 2rem 4rem;
  background: #fefefe;
  flex: 1;
  display: relative;
`;
const CheckoutHeader = styled.h2`
  font-weight: 700;
  font-size: 30px;
  color: #2757ed;
`;
const CustomBreadcrumb = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #878787;

  div,
  svg {
    margin: 0.4rem;
  }
`;
const AgreementDiv = styled.div`
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #878787;
  line-height: 18px;

  a {
    font-size: 15px;
    text-decoration: underline;
  }
`;

const CheckoutPage = () => {
  const history = useHistory();
  const { slug } = useParams();
  const location = useLocation();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [plan, SetPlan] = useState({});
  const [payments, SetPayments] = useState([]);
  const [checkoutProgress, SetCheckoutProgress] = useState(0);
  const [isProcessing, SetIsProcessing] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState('new');
  const [voucherCode, setVoucherCode] = useState('');

  const SearchComponent = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const search = SearchComponent();
  const additionalEmployeeSlot = Math.abs(
    parseInt(search.get('additional_employee_slot') || 0)
  );
  const duration = Math.abs(parseInt(search.get('duration') || 0));

  useEffect(() => {
    if (location.pathname.includes('/common/checkout/')) {
      SetCheckoutProgress(2);
      setSubscriptionType('renew');
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(plan).length) {
      api
        .getPlans()
        .then((res) => {
          if (res.length) {
            SetPlan(res.find((item) => item.slug === slug));
          } else {
            SetPlan(null);
          }
        })
        .catch((err) => {
          SetPlan(null);
          console.log(err);
        });
    }
  }, [plan]);

  useEffect(() => {
    if (payments != null && !payments.length) {
      api
        .getPayments()
        .then((res) => {
          if (res.length) {
            SetPayments(res);
          } else {
            SetPayments(null);
          }
        })
        .catch((err) => {
          SetPayments(null);
          console.log(err);
        });
    }
  }, [payments]);

  const onSubmit = handleSubmit((data) => {
    const paymentType = data.payment;
    delete data.payment;

    const users = data;
    if (paymentType === 'maya') {
      payUsingMaya(users);
    }
    if (paymentType === 'dragonpay') {
      payUsingDragonPay(users);
    }
    if (paymentType === 'paymongo') {
      payUsingPaymongo(users);
    }
  });

  const ChevronSvg = ({ fill }) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='5'
        height='8'
        viewBox='0 0 5 8'
        fill='none'
      >
        <path
          d='M0 0.715152L0.76264 0L5 4L0.758355 8L0 7.28485L3.48329 4L0 0.715152Z'
          fill={fill}
        />
      </svg>
    );
  };

  const payUsingMaya = (users) => {
    Swal.fire({
      icon: 'info',
      html: `<div style="font-size: 20px;">Are you sure you want to pay using</div><div><img src="${static_images.maya}" alt="maya_logo" style="height: 50px;">&nbsp;?</div>`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      heightAuto: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          headers: {
            'X-CSRFToken': document.getElementsByName('csrfmiddlewaretoken')[0]
              .defaultValue,
            Authorization: `Token ${
              document.getElementsByName('token')[0].defaultValue
            }`,
          },
        };
        const data = {
          users: users,
          path: window.location.pathname,
          url: window.location.origin,
          additional_employees: additionalEmployeeSlot,
          plan_id: plan.id,
          payment_id: payments.find((item) => item.payment_type == 'maya').id,
          subscription_type: subscriptionType,
          periodicity: duration ? 'yearly' : 'monthly',
          periodicity_duration: duration,
          voucher_code: voucherCode,
        };
        SetIsProcessing(true);
        axios
          .post('/api/maya-checkout/', data, headers)
          .then((response) => {
            setTimeout(() => {
              let popupWindow = window.open(
                response.data.redirectUrl,
                'popupWindow',
                'width=720,height=720'
              );
              setTimeout(() => {
                if (popupWindow) {
                  window.location.replace(response.data.callback_url);
                }
              }, 500);
            }, 500);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          })
          .finally(() => {
            SetIsProcessing(false);
          });
      }
    });
  };

  const payUsingDragonPay = (users) => {
    Swal.fire({
      icon: 'info',
      html: `<div style="font-size: 20px;">Are you sure you want to pay using</div><div><img src="${static_images.dragonpay}" alt="dragonpay_logo" style="height: 50px;">&nbsp;?</div>`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      heightAuto: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          headers: {
            'X-CSRFToken': document.getElementsByName('csrfmiddlewaretoken')[0]
              .defaultValue,
            Authorization: `Token ${
              document.getElementsByName('token')[0].defaultValue
            }`,
          },
        };
        const data = {
          users: users,
          path: window.location.pathname,
          url: window.location.origin,
          additional_employees: additionalEmployeeSlot,
          plan_id: plan.id,
          payment_id: payments.find((item) => item.payment_type == 'dragonpay')
            .id,
          subscription_type: subscriptionType,
          periodicity: duration ? 'yearly' : 'monthly',
          periodicity_duration: duration,
          voucher_code: voucherCode,
        };
        SetIsProcessing(true);
        axios
          .post('/api/pay-using-dragonpay/', data, headers)
          .then((response) => {
            setTimeout(() => {
              let popupWindow = window.open(
                response.data.redirectUrl,
                'popupWindow',
                'width=720,height=720'
              );
              setTimeout(() => {
                if (popupWindow) {
                  window.location.replace(response.data.callback_url);
                }
              }, 500);
            }, 500);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          })
          .finally(() => {
            SetIsProcessing(false);
          });
      }
    });
  };

  const payUsingPaymongo = (users) => {
    Swal.fire({
      icon: 'info',
      html: `<div style="font-size: 20px;">Are you sure you want to pay using</div><div><img src="${static_images.paymongo}" alt="paymongo_logo" style="height: 50px;">&nbsp;?</div>`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      heightAuto: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          headers: {
            'X-CSRFToken': document.getElementsByName('csrfmiddlewaretoken')[0]
              .defaultValue,
            Authorization: `Token ${
              document.getElementsByName('token')[0].defaultValue
            }`,
          },
        };
        const data = {
          users: users,
          path: window.location.pathname,
          url: window.location.origin,
          additional_employees: additionalEmployeeSlot,
          plan_id: plan.id,
          payment_id: payments.find((item) => item.payment_type == 'paymongo')
            .id,
          subscription_type: subscriptionType,
          periodicity: duration ? 'yearly' : 'monthly',
          periodicity_duration: duration,
          voucher_code: voucherCode,
        };
        SetIsProcessing(true);
        axios
          .post('/api/pay-using-paymongo-links/', data, headers)
          .then((response) => {
            setTimeout(() => {
              let popupWindow = window.open(
                response.data.data.attributes.checkout_url,
                'popupWindow',
                'width=720,height=720'
              );
              setTimeout(() => {
                if (popupWindow) {
                  window.location.replace(response.data.callback_url);
                }
              }, 500);
            }, 500);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          })
          .finally(() => {
            SetIsProcessing(false);
          });
      }
    });
  };

  const goBack = () => {
    if (checkoutProgress <= 0) {
      history.goBack();
    } else {
      if (subscriptionType == 'renew') {
        history.goBack();
      } else {
        SetCheckoutProgress(checkoutProgress - 1);
      }
    }
  };

  return (
    <div className='d-flex h-100'>
      <BackButton className='btn' onClick={goBack}>
        <i className='fa fa-chevron-left' aria-hidden='true'></i>
      </BackButton>
      <CheckoutFormDiv>
        <div className='text-center'>
          <CheckoutHeader>YAHSHUA Payroll Solutions</CheckoutHeader>
        </div>
        <CustomBreadcrumb className='d-flex justify-content-center align-items-center'>
          <div className='text-black'>Information</div>
          <ChevronSvg
            fill={[1, 2].includes(checkoutProgress) ? '#000000' : '#878787'}
          />
          <div
            className={classNames(
              [1, 2].includes(checkoutProgress) ? 'text-black' : '',
              ''
            )}
          >
            Billing Address
          </div>
          <ChevronSvg fill={checkoutProgress == 2 ? '#000000' : '#878787'} />
          <div
            className={classNames(
              checkoutProgress == 2 ? 'text-black' : '',
              ''
            )}
          >
            Payment
          </div>
        </CustomBreadcrumb>
        {checkoutProgress == 0 && (
          <InformationForm
            register={register}
            errors={errors}
            setError={setError}
            handleSubmit={handleSubmit}
            SetCheckoutProgress={SetCheckoutProgress}
          />
        )}
        {checkoutProgress == 1 && (
          <AddressForm
            register={register}
            errors={errors}
            setError={setError}
            getValues={getValues}
            handleSubmit={handleSubmit}
            SetCheckoutProgress={SetCheckoutProgress}
          />
        )}
        {checkoutProgress == 2 && (
          <PaymentOption
            payments={payments}
            errors={errors}
            setError={setError}
            register={register}
            onSubmit={onSubmit}
            isProcessing={isProcessing}
          />
        )}
        <AgreementDiv>
          By clicking Next, you agree to our{' '}
          <a href='#' data-toggle='modal' data-target='#privacyModal'>
            Terms of Service
          </a>
          ,{' '}
          <a href='#' data-toggle='modal' data-target='#privacyModal'>
            Privacy Notice
          </a>
          , and{' '}
          <a href='#' data-toggle='modal' data-target='#privacyModal'>
            Personal Data Collection and Disclosure Policy
          </a>
          .
        </AgreementDiv>
      </CheckoutFormDiv>
      <OrderedSection
        title={plan.name}
        price={plan.price}
        plan_employee_slot={plan.max_employees}
        added_employee_slot={additionalEmployeeSlot}
        setVoucherCode={setVoucherCode}
        duration={duration}
      />
      <div className='bg-loginscreen'>
        <div
          className='modal inmodal'
          id='privacyModal'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content animated bounceInUp'>
              <div className='modal-body'>
                <iframe id='myFrame' src={privacyUrl}></iframe>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-white'
                  data-dismiss='modal'
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
