import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import classNames from '../helpers/classNames';

import CheckIcon from '../svg/CheckIcon';
import ToggleOffIcon from '../svg/ToggleOffIcon';

const GradientBorderDiv = styled.div`
  background: linear-gradient(
    180deg,
    #2757ed 0%,
    rgba(148, 215, 255, 0.3) 100%
  );
  width: 350px;
  box-shadow: 0px 0px 10px rgba(4, 33, 123, 0.1);
  border-radius: 10px;
  padding: 1px;
`;
const ParentCardDiv = styled.div`
  background: #ffffff;
  border-radius: 10px;
`;
const SeparatorDiv = styled.div`
  border-top: 1px solid #878787;
  margin-bottom: 1rem;
`;
const PlanNameHeader = styled.h2`
  font-weight: 700;
`;
const PlanFeaturesDiv = styled.div`
  height: 85px;
  overflow: hidden;
`;
const CustomMoreDiv = styled.div`
  height: 19px;

  button {
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 0.5rem !important;
    cursor: pointer;
    color: #2757ed;
  }
`;
const PlanFeatureDiv = styled.div`
  margin-bottom: 0.8rem;
`;
const PlanPriceSpan = styled.span`
  font-weight: 700;
  font-size: 25px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #2757ed;
`;
const PlanPeriodicitySpan = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2757ed;
`;
const EmployeeSlotLabel = styled.label`
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
  margin-bottom: 3px;
`;
const EmployeeSlotInput = styled.input`
  font-size: 15px;
  border-radius: 5px;
  padding: 5px;
  font-weight: 500;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 1 !important;
  }
`;
const VatLabel = styled.div`
  color: #acacab;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.26px;
`;
const SubscribeBtn = styled.button`
  height: 53px;
  border-radius: 10px;
  background-color: #2757ed !important;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: #ffffff !important;
  border: 0;

  &:hover {
    background-color: #4f80ff !important;
  }
`;

const PlanCard = ({
  id,
  slug,
  name,
  description,
  price,
  employeeSlots,
  features,
  periodicity,
  periodicityDuration,
  isShowMore,
  showMore,
}) => {
  const history = useHistory();
  const [addedSlots, setAddedSlots] = useState(0);
  const [totalSlots, setTotalSlots] = useState(employeeSlots);
  const [isError, setIsError] = useState(false);

  const formatPrice = (value) => {
    const val = (value / 1).toFixed(2).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderPeriodicity = () => {
    let label = 'month';
    if (periodicity === 'yearly') {
      label = 'year';
      if (periodicityDuration > 1) {
        label = `${periodicityDuration} years`;
      }
    }
    return label;
  };

  const periodicityPrice = () => {
    if (isNaN(addedSlots)) {
      return price;
    }
    let customPrice = price + addedSlots * 60;
    if (periodicity === 'yearly') {
      customPrice = customPrice * 12 * periodicityDuration;
    }
    return customPrice;
  };

  const PlanLogo = () => {
    return (
      <svg
        width='82'
        height='88'
        viewBox='0 0 82 88'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#yp_logo)'>
          <rect x='3' y='2' width='76' height='82' rx='10' fill='#F3F5FD' />
        </g>
        <path
          d='M35.72 51.6306L35.7058 47.2655L35.6924 43.1438C35.6924 43.1438 45.7142 37.1038 52.3434 33.1084L56.1027 39.3462L35.72 51.6306Z'
          fill='#0081CD'
        />
        <path
          d='M31.5038 25.2435L35.1363 18.9235L56.0944 31.2763L56.0944 34.9949L56.0944 39.3516L31.5038 25.2435Z'
          fill='#010F7C'
        />
        <path
          d='M27.7341 21.7719L35.135 18.9241V61.8961L27.7341 65.7779V21.7719Z'
          fill='#02B3BE'
        />
        <defs>
          <filter
            id='yp_logo'
            x='0'
            y='0'
            width='82'
            height='88'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='1.5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.215686 0 0 0 0 0.207843 0 0 0 0 0.188235 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_568_30'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_568_30'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    );
  };

  return (
    <div className='mt-5 mx-4'>
      <GradientBorderDiv>
        <ParentCardDiv className='py-4 px-3'>
          <div className='d-flex mx-1'>
            <div>
              <PlanLogo />
            </div>
            <div className='mx-4'>
              <PlanNameHeader>{name}</PlanNameHeader>
              <p>{description}</p>
            </div>
          </div>
          <SeparatorDiv />
          <PlanFeaturesDiv
            className={classNames(isShowMore ? 'remove-height' : '', '')}
          >
            {features.map((feature, index) => {
              return (
                <main key={index}>
                  {index + 1 != features.length && (
                    <PlanFeatureDiv key={index} className='d-flex ml-2'>
                      <CheckIcon />
                      <span className='ml-3'>{feature}</span>
                    </PlanFeatureDiv>
                  )}
                  {index + 1 == features.length && (
                    <PlanFeatureDiv key={index} className='d-flex ml-2 mb-0'>
                      <CheckIcon />
                      <span className='ml-3'>{feature}</span>
                    </PlanFeatureDiv>
                  )}
                </main>
              );
            })}
          </PlanFeaturesDiv>
          <CustomMoreDiv className='mt-1 mb-1'>
            {features.length > 3 && !isShowMore && (
              <button
                type='button'
                onClick={() => {
                  showMore(id);
                }}
              >
                more...
              </button>
            )}
          </CustomMoreDiv>
          <div className='form-group'>
            <EmployeeSlotLabel htmlFor='number_of_employees'>
              Number of employees
            </EmployeeSlotLabel>
            <EmployeeSlotInput
              id='number_of_employees'
              type='number'
              className={classNames(
                isError ? 'is-invalid' : '',
                'form-control'
              )}
              placeholder={employeeSlots}
              value={totalSlots}
              onChange={({ target }) => {
                let value = parseInt(target.value);
                if (value < employeeSlots) {
                  setIsError(true);
                  setTotalSlots(value);
                  setAddedSlots(0);
                } else {
                  if (!isNaN(value)) {
                    setIsError(false);
                  }
                  setTotalSlots(value);
                  setAddedSlots(value - employeeSlots);
                }
              }}
            />
          </div>
          <div className='text-center'>
            <PlanPriceSpan>PHP {formatPrice(periodicityPrice())}</PlanPriceSpan>
            <PlanPeriodicitySpan>/{renderPeriodicity()}</PlanPeriodicitySpan>
          </div>
          <VatLabel className='d-flex justify-content-center align-items-center pt-2 pb-3'>
            <ToggleOffIcon /> <span className='pl-2'>*VAT Exclusive</span>
          </VatLabel>
          <SubscribeBtn
            type='button'
            className='btn btn-block'
            disabled={isError}
            onClick={() => {
              let params = {
                additional_employee_slot: addedSlots,
              };
              if (periodicity === 'yearly') {
                params.duration = periodicityDuration;
              }
              let searchParams = new URLSearchParams(params);
              history.push(`/checkout/${slug}/?${searchParams}`);
            }}
          >
            SUBSCRIBE
          </SubscribeBtn>
        </ParentCardDiv>
      </GradientBorderDiv>
    </div>
  );
};

export default PlanCard;
