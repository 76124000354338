import React from "react";

function InputField({
    id,
    className,
    name,
    type,
    onChange,
    placeholder,
    value,
    defaultChecked,
    error
}) {
    let wrapperClase = className;
    if (error && error.length > 0) {
        wrapperClase += " is-invalid";
    }
    return ( 
        <input
            className={wrapperClase}
            id={id} 
            name={name} 
            type={type}
            placeholder={placeholder}
            onChange={onChange} 
            value={value} 
            defaultChecked={defaultChecked}
        />
    )
}

export default InputField;