import React from "react";

function Button({
    className,
    type,
    title,
    process,
    processMsg,
    onClick,
    text
}) {
    return ( 
        <button 
            className={className} 
            type={type} 
            title={title}
            disabled={process} 
            onClick={onClick}
        >
            {process ? processMsg : text}
        </button>
    )
}

export default Button;