import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import * as api from '../api/api';

import Sally from '../svg/Sally';

const OrderedDiv = styled.div`
  padding: 2rem 4rem;
  box-shadow: -10px 0px 25px rgba(39, 87, 237, 0.08);
  flex: 1;
`;
const OrderedPlanDiv = styled.div`
  span {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #373530;
  }
  p {
    font-size: 10px;
  }
`;
const OrderedPlanPriceDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  label {
    font-weight: 400;
    font-size: 15px;
  }
`;
const VoucherInput = styled.input`
  border: 1px solid #acb9cb;
  border-radius: 5px;
  font-size: 15px;
  padding: 0.7rem;
  margin-right: 1.5rem;
`;
const ApplyBtn = styled.button`
  height: 47px;
  width: 153px;
  border-radius: 5px;
  background: #2757ed;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background-color: #4f80ff !important;
  }
`;
const PriceBreakdownDiv = styled.div`
  div > h3 {
    font-size: 15px;
    font-weight: 400;
  }
  div:last-child h3 {
    font-weight: 600 !important;
    color: #2757ed;
  }
`;
const SalesConditionDiv = styled.div`
  font-size: 0.9vw;
  letter-spacing: 0.02em;
  color: #f15353;
`;

const OrderedSection = ({
  title,
  price,
  setVoucherCode,
  plan_employee_slot,
  added_employee_slot,
  duration,
}) => {
  const { slug } = useParams();
  const [inputtedCode, setInputtedCode] = useState('');
  const [discount, setDiscount] = useState(0);

  const formatPrice = (value) => {
    const val = (value / 1).toFixed(2).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const periodicityPrice = () => {
    if (!price) {
      return 0;
    }
    let customPrice = price;
    if (duration) {
      customPrice = customPrice * 12 * duration;
    }
    return customPrice;
  };

  const getAddedPrice = () => {
    return periodicityPrice() + added_employee_slot * 60;
  };

  const getDiscountPrice = () => {
    return (getAddedPrice() / (1 + 12 / 100)) * (discount / 100);
  };

  const getNetOfDiscount = () => {
    return getAddedPrice() - getDiscountPrice();
  }

  const getNumberEmployees = () => {
    return plan_employee_slot + added_employee_slot;
  };

  const lockInPeriod = () => {
    let periodicity = 'Monthly';
    if (duration) {
      periodicity = `${duration} year`;
      if (duration > 1) {
        periodicity = `${duration} years`;
      }
    }
    return periodicity;
  };

  const availDiscount = () => {
    let data = {
      code: inputtedCode,
      employees_slot: plan_employee_slot + added_employee_slot,
      slug: slug,
    };
    api
      .availDiscount(data)
      .then((res) => {
        if (Object.keys(res).length) {
          setVoucherCode(res.code);
          setDiscount(res.discount);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const PlanLogo = () => {
    return (
      <svg
        width='65'
        height='69'
        viewBox='0 0 65 69'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#yp_logo)'>
          <rect x='3' y='2' width='59' height='63' rx='10' fill='#F3F5FD' />
        </g>
        <path
          d='M28.401 40.1309L28.39 36.7772L28.3796 33.6105C28.3796 33.6105 36.1597 28.97 41.306 25.9004L44.2245 30.6928L28.401 40.1309Z'
          fill='#0081CD'
        />
        <path
          d='M25.1279 19.8578L27.9479 15.0021L44.218 24.4928L44.218 27.3497L44.218 30.6969L25.1279 19.8578Z'
          fill='#010F7C'
        />
        <path
          d='M22.2015 17.1907L27.9469 15.0027V48.0178L22.2015 51.0001V17.1907Z'
          fill='#02B3BE'
        />
        <defs>
          <filter
            id='yp_logo'
            x='0'
            y='0'
            width='65'
            height='69'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='1.5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.215686 0 0 0 0 0.207843 0 0 0 0 0.188235 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_568_36'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_568_36'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    );
  };

  return (
    <OrderedDiv>
      <div className='d-flex mb-3'>
        <div className='mr-3'>
          <PlanLogo />
        </div>
        <OrderedPlanDiv className='media-body'>
          <span>{title}</span>
          <p>
            Number of Employees: {getNumberEmployees()}
            <br />
            Lock-in Period: {lockInPeriod()}
          </p>
        </OrderedPlanDiv>
        <OrderedPlanPriceDiv className='media-body text-right'>
          <label>PHP {formatPrice(getAddedPrice())}</label>
        </OrderedPlanPriceDiv>
      </div>
      <div className='form-group d-flex mb-4'>
        <VoucherInput
          type='text'
          className='form-control'
          placeholder='Enter Discount Code'
          value={inputtedCode}
          onChange={({ target }) => {
            setInputtedCode(target.value);
            if (!target.value) {
              if (discount) {
                setVoucherCode('');
                setDiscount(0);
              }
            }
          }}
        />
        <ApplyBtn
          className='btn custom-disabled-btn'
          disabled={!inputtedCode}
          onClick={() => availDiscount()}
        >
          Apply
        </ApplyBtn>
      </div>
      <PriceBreakdownDiv className='mb-4'>
        <div className='d-flex justify-content-between'>
          <h3>Subtotal</h3>
          <h3>PHP {formatPrice(getAddedPrice())}</h3>
        </div>
        {!!discount && (
          <>
            <div className='d-flex justify-content-between'>
              <h3>Discount %</h3>
              <h3>{discount}%</h3>
            </div>
            <div className='d-flex justify-content-between'>
              <h3>Discounted Amount</h3>
              <h3>PHP {formatPrice(getDiscountPrice())}</h3>
            </div>
            <div className='d-flex justify-content-between'>
              <h3>Net of Discount</h3>
              <h3>PHP {formatPrice(getNetOfDiscount())}</h3>
            </div>
          </>
        )}
        <div className='d-flex justify-content-between'>
          <h3>VAT (12%)</h3>
          <h3>PHP {formatPrice(getNetOfDiscount() * (12 / 100))}</h3>
        </div>
        <div className='d-flex justify-content-between'>
          <h3>Total</h3>
          <h3>PHP {formatPrice(getNetOfDiscount() * (1 + 12 / 100))}</h3>
        </div>
      </PriceBreakdownDiv>
      <SalesConditionDiv>
        <i>Please note that the subscription fee and related services are final and
        non-refundable.</i>
      </SalesConditionDiv>
      <div className='text-center mt-4'>
        <Sally />
      </div>
    </OrderedDiv>
  );
};

export default OrderedSection;
