import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import * as api from "../api/api";

const GoogleAuthCallback = () => {

    const SearchComponent = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const search = SearchComponent();

    useEffect(() => {
        const code = search.get('code') || "";
        if (code) {
            const data = {code};
            api.sso_complete_callback(data).then(res => {
                if (res) {
                    passDataToMain(res);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [])

    const passDataToMain = (code) => {
        window.opener.postMessage(code, window.location.origin);
        window.close();
    };

    return (
        <div>Authenticating ...</div>
    )
}

export default GoogleAuthCallback;