import React from 'react';

const ToggleOffIcon = () => {
    return (
      <svg
        width='26'
        height='15'
        viewBox='0 0 26 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.9091 0H7.09091C3.17909 0 0 3.3625 0 7.5C0 11.6375 3.17909 15 7.09091 15H18.9091C22.8209 15 26 11.6375 26 7.5C26 3.3625 22.8209 0 18.9091 0ZM18.9091 12.5H7.09091C4.47909 12.5 2.36364 10.2625 2.36364 7.5C2.36364 4.7375 4.47909 2.5 7.09091 2.5H18.9091C21.5209 2.5 23.6364 4.7375 23.6364 7.5C23.6364 10.2625 21.5209 12.5 18.9091 12.5ZM7.09091 3.75C5.12909 3.75 3.54545 5.425 3.54545 7.5C3.54545 9.575 5.12909 11.25 7.09091 11.25C9.05273 11.25 10.6364 9.575 10.6364 7.5C10.6364 5.425 9.05273 3.75 7.09091 3.75Z'
          fill='#ACACAB'
        />
      </svg>
    );
  };

export default ToggleOffIcon;