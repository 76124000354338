import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import ForgotPasswordForm from "./ForgotPasswordForm";

import * as api from "../api/api";
 
export function ForgotPasswordPage() {

    const history = useHistory();
    const [users, setUsers] = useState({
        email: ""
    });
    const [errors, setErrors] = useState({});
    const [process, setProcess] = useState(false);

    function handleChange({target}) {
        const updatedUsers = {...users, [target.name]: target.value};
        setUsers(updatedUsers);
        if (!formIsValid(updatedUsers));
    }

    function validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    function formIsValid() {
        const { email } = users;
        const errors = {};

        if (!email) errors.email = "Email is required.";
        if (email && !validateEmail(email)) errors.email = "Email is invalid.";

        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    function handleResetPassword(event) {
        event.preventDefault();
        if (!formIsValid(users)) return;
        setProcess(true);
        const data = {
            email: users.email
        };
        api.resetPassword(data).then(response => {
            setProcess(false);
            toast.success(response);
        }).catch(error => {
            setProcess(false);
            toast.error(error.response.data);
        });
    }

    return (
        <>
            <ForgotPasswordForm
                history={history}
                users={users}
                errors={errors}
                onChange={handleChange}
                handleResetPassword={handleResetPassword}
                process={process}
            />
        </>
    );
}

export default ForgotPasswordPage;
