import React, { useState } from "react";
import { toast } from "react-toastify";

import ForceChangePasswordForm from "./ForceChangePasswordForm";

import * as api from "../api/api";

export function ForceChangePasswordPage() {

    const [users, setUsers] = useState({
        password: "",
        repassword: "",
    });
    const [errors, setErrors] = useState({});
    const [process, setProcess] = useState({
        change_password: false,
        logout: false,
    });

    function handleChange({target}) {
        const updatedUsers = {...users, [target.name]: target.value};
        setUsers(updatedUsers);
        if (!formIsValid(updatedUsers));
    }

    function formIsValid(users) {
        const { password, repassword } = users;
        const errors = {};

        if (!password) errors.password = "Password is required.";
        if (!repassword) errors.repassword = "Confirm Password is required.";
        if (repassword && (password !== repassword)) errors.repassword = "Confirm Password doesn't match.";

        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    function handleLogout() {
        setTimeout(function(){
            setProcess({...process, "logout": false});
            window.location.href = "/logout";
        }, 500);
    }

    function handleForceChangePassword(event) {
        event.preventDefault();
        if (!formIsValid(users)) return;
        setProcess({...process, "change_password": true});
        const data = {
            password: users.password,
            repassword: users.repassword
        };
        api.forceChangePassword(data).then(response => {
            setProcess({...process, "change_password": false});
            toast.success(response);
            setTimeout(function(){
                window.location.href = "/dashboard";
            }, 500);
        }).catch(error => {
            setProcess({...process, "change_password": false});
            toast.error(error.response.data);
        });
    }

    return (
        <>
            <ForceChangePasswordForm 
                users={users}
                errors={errors}
                onChange={handleChange}
                handleForceChangePassword={handleForceChangePassword}
                process={process}
                handleLogout={handleLogout}
            />
        </>
    );
}

export default ForceChangePasswordPage;
