import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const middleware = [thunk];

export const configureStore =  createStore(
    rootReducer,
    applyMiddleware(...middleware)
)

export const persistor = persistStore(configureStore);

export const export_objects = {
    configureStore, 
    persistor
}

export default export_objects;