import React from 'react';
import styled from 'styled-components';

const StatusCardDiv = styled.div`
  border: 1px solid #4f80ff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 8px;

  h4 {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
  button {
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #2757ed;
    text-decoration: underline;
    cursor: pointer;
  }
  button:hover {
    color: #4f80ff;
  }
`;
const CustomH2 = styled.h2`
  font-weight: 600 !important;
  font-size: 28px;
`;

const PurchaseStatusCard = ({ plan, handleCancelTransaction }) => {
  return (
    <StatusCardDiv className='text-center'>
      {plan.status === 'Failed' || plan.status === 'Cancelled' ? (
        <>
          <h4>You don't have any active subscription yet</h4>
        </>
      ) : plan.status === 'Processing' ? (
        <h4>You're almost there! waiting for the payment to success.</h4>
      ) : plan.status === 'Paid' ? (
        plan.is_ended ? (
          <>
            <h4>Your subscription has ended.</h4>
          </>
        ) : (
          <>
            <CustomH2>Congratulations!</CustomH2>
            <h4>You have successfully purchased your plan.</h4>
          </>
        )
      ) : plan.status === 'Pending' ? (
        <>
          <h4>Did something went wrong with your payment?</h4>
          <h4>
            <button
              onClick={() => {
                window.open(
                  plan.checkout_url,
                  'popupWindow',
                  'width=720,height=720'
                );
              }}
            >
              Click here to go to the payment window
            </button>
            &nbsp;or&nbsp;
            <button type='button' onClick={() => handleCancelTransaction()}>
              Cancel Purchase
            </button>
          </h4>
        </>
      ) : null}
    </StatusCardDiv>
  );
};

export default PurchaseStatusCard;
