import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router";

function ScrollToTop(props) {
    
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;